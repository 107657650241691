<template>
  <ModalTemplate :loading="apiLoading">
    <div id="root" slot="body">
      <!-- Form -->
      <div class="sensor-form">
        <h2>Filtro gateway in inventario:</h2>
        <div class="form-field">
          <label for="eui">Eui:</label>
          <input type="text" placeholder="Filtra per eui..." name="eui" ref="eui" min="0" step="any" class="input-text" spellcheck="false" maxlength="23e" v-model="search" />
        </div>
        <div class="form-field">
          <label for="type">Rev:</label>
          <select id="type" v-model="rev">
            <option v-for="revision in gwRevs" :key="revision" :value="revision">{{ revision + (isLastRev(revision) ? " (latest)" : " (legacy)") }}</option>
          </select>
        </div>
        <div class="form-field">
          <label for="type">Region:</label>
          <select id="type" v-model="region" :disabled="lockedRegion">
            <option v-for="reg in gwRegions" :key="reg" :value="reg">{{ reg }}</option>
          </select>
        </div>
        <div class="form-field">
          <button class="std-button noselect" @click="setSelectedAvailableSensors([])">Reset</button>
        </div>
      </div>

      <!-- SENSORE -->
      <!-- Dettagli singolo sensore -->
      <div class="sensor-details" v-if="getSelectedGWObj">
        <h2>Dettagli gateway selezionato:</h2>
        <p>
          <span class="strong"> EUI: {{ getSelectedGWObj.customFields.gw_eui }}</span>
        </p>
        <p><span class="strong"> FW: </span>{{ getSelectedGWObj.customFields.fwver || " - " }}</p>
        <p><span class="strong"> Lot. num.: </span>{{ getSelectedGWObj.lot_num || " - " }}</p>
        <p><span class="strong"> Col Ver.: </span>{{ getSelectedGWObj["coll ver"] || " - " }}</p>
        <p><span class="strong"> Distributore: </span>{{ getSelectedGWObj.resellerID || " - " }}</p>
        <p><span class="strong"> NS: </span>{{ getSelectedGWObj.customFields.netserv || " - " }}</p>
        <p><span class="strong"> Region: </span>{{ getSelectedGWObj.customFields.region || " - " }}</p>
        <p><span class="strong"> Data collaudo: </span>{{ new Date(getSelectedGWObj.date).toLocaleString() }}</p>
      </div>

      <div class="sensor-details empty-container" v-else>
        <h2>Nessun sensore selezionato...</h2>
      </div>
      <!-- Lista -->
      <div class="sensor-list">
        <h2>Lista gateway disponibili ({{ (getAvailableDevices && getAvailableDevices.sensors.length) || " - " }}):</h2>
        <div class="list-wrapper scroll">
          <div
            :class="[isSensorSelected(gw.customFields.gw_eui) ? 'selected-item' : '', 'sensor-item noselect-hard']"
            v-for="(gw, index) in (getAvailableDevices || {}).sensors"
            :key="gw.customFields.gw_eui"
            @click="handleSensorItemClick(gw.customFields.gw_eui)"
          >
            <p>{{ index + 1 }}</p>
            <p>{{ gw.customFields.gw_eui + " (" + gw.customFields.vendor + " - " + gw.customFields.model + ")" }}</p>
          </div>
        </div>
      </div>
      <div class="bottom-controls">
        <button class="std-button noselect" @click="$emit('close')">Annulla</button>
        <button class="std-button green-bg noselect" :disabled="!selectedGatewayID" @click="assignGateway">Assegna</button>
      </div>
    </div>
  </ModalTemplate>
</template>

<script>
/*
  Riceve come props il tipo di sensore da cercare di default.
  Si occupa di visualizzare l'inventario sensori secondo i filtri, e di dare
  la possibilità all'utente di assegnare il sensore selezionato alla struttura.
*/
import ModalTemplate from "./ModalTemplate.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "gateway-search",
  components: {
    ModalTemplate,
  },
  props: {
    lockedRegion: String, // Costringo alla ricerca in questa region
  },
  data() {
    return {
      search: "", // Search query string
      type: "", // Search type
      rev: "", // Search rev
      region: "", // Search region
      gwRevs: ["1.0"],
      gwRegions: ["EU", "AU", "US"],
      awaitingSearch: false,
      apiLoading: false,
      selectedGatewayID: null,
    };
  },
  methods: {
    ...mapActions({
      fetchAvailableDevices: "admin/fetchAvailableDevices",
      assignGatewayToStructure: "admin/assignGatewayToStructure",
    }),
    /* Ritorna true se la revisione passata è l'ultima disponibile per quel tipo di sensore */
    isLastRev(rev) {
      return this.gwRevs.indexOf(rev) === this.gwRevs.length - 1;
    },
    /* Ritorna true se il sensore con ID passato è selezionato */
    isSensorSelected(gwID) {
      return this.selectedGatewayID === gwID;
    },
    /* Fetcha la lista di sensori disponibili (dopo delay ms), seleziona il primo e fetcha i dettagli di questo */
    fetchList(delay) {
      if (!this.awaitingSearch) {
        setTimeout(async () => {
          //this.fetchResults({ query: this.search });
          this.apiLoading = true;
          await this.fetchAvailableDevices({ type: this.type, eui: this.search, rev: this.rev, region: this.region });
          // Se avevo un solo sensore selezionato, garantisco di selezionarne un altro
          if (this.getAvailableDevices.sensors.length > 0) {
            this.selectedGatewayID = this.getAvailableDevices.sensors[0].customFields.gw_eui;
          }
          this.apiLoading = false;
          this.awaitingSearch = false;
        }, delay); // 1 sec delay
      }
      this.awaitingSearch = true;
    },
    /* Assegna un gateway alla struttura selezionata */
    async assignGateway() {
      this.apiLoading = true;
      await this.assignGatewayToStructure(this.selectedGatewayID);
      this.apiLoading = false;
      this.$emit("close");
    },
    handleSensorItemClick(gwID) {
      this.selectedGatewayID = gwID;
    },
  },
  computed: {
    ...mapGetters({
      getAvailableDevices: "admin/getAvailableDevices",
      //getAvailableSensorDetails: "admin/getAvailableSensorDetails",
      //getSelectedAvailableSensors: "admin/getSelectedAvailableSensors",
    }),
    getSelectedGWObj() {
      if (this.selectedGatewayID) {
        return this.getAvailableDevices.sensors.find((gw) => gw.customFields.gw_eui === this.selectedGatewayID);
      } else {
        return null;
      }
    },
  },
  watch: {
    async getSelectedAvailableSensors(newSensors) {
      if (newSensors && newSensors.length === 1) {
        this.apiLoading = true;
        //await this.fetchAvailableSensorDetails(newSensors[0]);
        this.apiLoading = false;
      }
    },
    search() {
      // Quando esegue la ricerca con EUI
      this.fetchList(1000);
    },
    rev() {
      // Quando cambia la rev
      this.fetchList(0);
      //this.setSelectedAvailableSensors([]);
    },
    region() {
      // Quando cambia la region
      this.fetchList(0);
    },
  },
  mounted() {
    // All'avvio init
    this.apiLoading = true;
    this.type = "gateway";
    this.rev = "1.0";
    this.region = this.lockedRegion || "EU";
    this.fetchList(0);
  },
};
</script>
<style scoped>
p,
label {
  font-size: 14px;
  margin: 8px;
}
h2 {
  font-size: 16px;
  margin-bottom: 10px;
}
#root {
  display: grid;
  grid-template-rows: min-content 10fr;
  min-height: 300px;
}
.form-field {
  display: flex;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
}
.form-field > button {
  margin: 0 55px 0 95px;
  width: 100%;
}
label {
  color: white;
  /* padding: 14px; */
  text-align: left;
  min-width: 80px;
}
select {
  margin-left: 0;
  height: 35px;
  width: 220px;
}
input[type="number"],
input[type="text"] {
  width: calc(220px - 0.8rem);
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 50%;
  background-color: rgb(32, 34, 47) !important;
  border: none;
  padding: 0.4rem;
  color: white;
  font-size: 0.9em;
  outline: none !important;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  height: 24px;
}
input[type="number"]:disabled.valid-field,
input[type="text"]:disabled.valid-field {
  border: none;
}
.analog-threshold-field > input[type="number"] {
  grid-column: 2;
  margin-left: 10px;
}
input[type="number"],
input[type="text"]:enabled {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.sensor-form {
  padding: 20px;
  grid-column: 1;
  grid-row: 1;
  text-align: left;
  background-color: rgb(80, 84, 105);
  border: solid 2px rgba(0, 0, 0, 0.4);
}

.sensor-list {
  padding: 20px;
  grid-column: 2;
  grid-row: 1/3;
  min-width: 380px;
  background-color: rgb(80, 84, 105);
  text-align: left;
  padding-left: 20px;
  border: solid 2px rgba(0, 0, 0, 0.4);
}

.sensor-details {
  padding: 20px;
  min-width: 370px;
  background-color: rgb(80, 84, 105);
  border: solid 2px rgba(0, 0, 0, 0.4);
  grid-column: 1;
  grid-row: 2;
  text-align: left;
}

.bottom-controls {
  grid-column: 1/3;
  justify-content: space-around;
  align-items: center;
  display: flex;
}

.bottom-controls > button {
  margin: 12px 0;
  padding: 8px;
}

.green-bg {
  background-color: green;
}

.empty-container {
  min-height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.bright-container {
  background-color: rgb(80, 84, 105);
}

.strong {
  font-weight: 600;
  font-size: inherit;
}

.list-wrapper {
  background-color: rgb(45, 48, 65);
  padding: 12px;
  border-radius: 5px;
  height: 400px;
  overflow-y: scroll;
}

.sensor-item {
  background-color: rgba(0, 0, 0, 0.4);
  border: solid 1px white;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  padding: 2px 0;
  font-weight: 500;
}

.selected-item {
  background-color: rgb(47, 112, 177);
}

.sensor-item > p:first-child {
  font-weight: 800;
}
</style>
