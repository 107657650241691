<template>
  <div class="flip-container" v-if="this.localStructure || empty">
    <div id="card" v-if="!empty">
      <!-- Retro della Card -->
      <div class="back" v-bind:class="{ flipped: flipped, 'selected-card': selected }">
        <div class="content">
          <form class="form-wrap noselect">
            <div class="form-field" :class="[this.validName ? 'valid-field' : 'invalid-field']">
              <label for="name">Nome:</label>
              <input type="text" name="name" ref="name" v-model="localStructure.name" :disabled="!editing" required />
            </div>
            <div class="form-field" :class="[this.validAddress ? 'valid-field' : 'invalid-field']">
              <label for="address">Indirizzo:</label>
              <input type="text" name="address" v-model="localStructure.address" :disabled="!editing" required />
            </div>
            <div class="form-field" :class="[this.validLat ? 'valid-field' : 'invalid-field']">
              <label for="lat">Latitudine:</label>
              <input type="number" name="lat" v-model.number="localStructure.coordinates.lat" :disabled="!editing" required />
            </div>
            <div class="form-field" :class="[this.validLng ? 'valid-field' : 'invalid-field']">
              <label for="lng">Longitudine:</label>
              <input type="text" name="lng" v-model.number="localStructure.coordinates.lng" :disabled="!editing" required />
            </div>
            <div class="form-field" :class="[this.validSpans ? 'valid-field' : 'invalid-field']">
              <label for="spans">Campate:</label>
              <input type="number" step="1" name="spans" v-model.number="localStructure.spans" :disabled="!editing" required />
            </div>
            <div class="form-field" :class="['valid-field']">
              <label for="installDate">Data setup:</label>
              <input type="date" name="installDate" v-model="computedInstallDate" :disabled="!editing" />
            </div>
            <div class="form-field">
              <label for="region">Regione:</label>
              <!-- Disabilitato se struttura già esistente -->
              <select v-model="localStructure.loraRegion" :disabled="!editing || !structure.newOne" name="region">
                <option v-for="region in loraRegions" :key="region._id" :value="region._id">{{ region._id }}</option>
              </select>
            </div>
            <div class="inline-opts">
              <div class="form-field">
                <label for="fddEnabled">FDD:</label>
                <input type="checkbox" name="fddEnabled" v-model="localStructure.fddEnabled" :disabled="!editing" />
              </div>
              <div class="form-field">
                <label for="railEnabled">Rail:</label>
                <input type="checkbox" name="railEnabled" v-model="localStructure.railEnabled" :disabled="!editing" />
              </div>
              <div class="form-field">
                <label for="overlaysEnabled">Overlays:</label>
                <input type="checkbox" name="overlaysEnabled" v-model="localStructure.overlaysEnabled" :disabled="!editing" />
              </div>
            </div>
            <div class="form-field">
              <label for="type">Tipo:</label>
              <select v-model="localStructure.type" :disabled="!editing" name="type">
                <option value="bridge">Ponte</option>
                <option value="generic">Generico</option>
                <option value="rail">Ferrovia</option>
              </select>
            </div>
            <div class="form-button">
              <button type="button" class="button r1 c3 red" @click="deleteOldEvents">Elimina I DATI più vecchi di installDate</button>
            </div>
          </form>
        </div>
        <div class="back-buttons-container">
          <button id="edit" type="button" class="button r1 c3" @click="deleteStructure">Elimina</button>
          <button id="save" type="button" class="button r1 c5" @click="save">Salva</button>
          <button id="reset" type="button" class="button r1 c1" @click="reset">Annulla</button>
        </div>
      </div>
      <!-- Fronte della Card -->
      <div class="front" v-bind:class="{ flipped: flipped, 'selected-card': selected }" @click="selectStructure">
        <div class="heading">
          <h4>
            {{ "Struttura: " }}
            <span class="bold">{{ localStructure.name }}</span>
          </h4>
        </div>
        <div class="content">
          <div class="data-info">
            <p class="data-label">{{ "Numero devices installati" }}</p>
            <p class="data-value">
              {{ localStructure.sensors ? localStructure.sensors.length : " - " }}
            </p>
          </div>
          <div class="data-info">
            <p class="data-label">{{ "Luogo" }}</p>
            <p class="data-value">{{ localStructure.address }}</p>
          </div>
          <div class="data-info">
            <p class="data-label">Lora Region:</p>
            <p class="data-value">
              {{ localStructure.loraRegion || " - " }}
            </p>
          </div>
          <div class="data-info">
            <p class="data-label">RoamingID:</p>
            <p class="data-value">
              {{ localStructure.roamingID || " - " }}
            </p>
          </div>
          <div class="data-info">
            <p class="data-label">VirtualNetworkID:</p>
            <p class="data-value">
              {{ localStructure.virtualNetworkID || " - " }}
            </p>
          </div>
          <div class="data-info">
            <p class="data-label">Multicast Device:</p>
            <p class="data-value">
              {{ localStructure.multicastDevices ? localStructure.multicastDevices[0].multicastEUI : null || " - " }}
            </p>
          </div>
          <div class="data-info">
            <p class="data-label">{{ "Data installazione" }}</p>
            <p class="data-value">
              {{ new Date(localStructure.installDate).toLocaleDateString() || " - " }}
            </p>
          </div>
        </div>
        <div class="front-buttons-container">
          <button id="edit" type="button" class="button" @click="flipped = true" v-on:click.stop>Modifica</button>
        </div>
      </div>
    </div>
    <div v-else class="empty-card" @click="newStructure">
      <div class="heading">
        <h4>
          <span class="bold">Aggiungi una struttura</span>
        </h4>
      </div>
      <div class="empty-content">
        <img class="plus-circle" src="../../../../public/assets/icons/plus-circle.svg" />
      </div>
    </div>
  </div>
</template>
<script>
import validate from "../../../helpers/validate";
import { loraRegions } from "../../../helpers/sensors";
export default {
  name: "flip-struct",
  props: {
    structure: Object,
    empty: Boolean,
    selected: Boolean,
  },
  data() {
    return {
      localStructure: null,
      valid: true,
      editing: true,
      flipped: false,
    };
  },
  methods: {
    edit() {
      this.editing = !this.editing;
    },
    deleteStructure() {
      this.$emit("delete-structure", this.localStructure);
    },
    deleteOldEvents() {
      this.$emit("delete-old-events", this.localStructure);
    },
    save() {
      /* Evento di Salvataggio Struttura */
      if (this.validForm) {
        // Create a valid Date to prevent validation error
        const structResp = JSON.parse(JSON.stringify(this.localStructure));
        delete structResp.editing;
        delete structResp.flipped;
        delete structResp.valid;
        delete structResp.newOne;
        structResp.installDate = new Date(structResp.installDate);
        this.$emit("save-structure", structResp);
        this.localStructure.newOne = false; // Se era nuova, la flaggo come ok
        this.flipped = false;
      }
    },
    reset() {
      /* Evento di ripristino vecchi dati */
      if (!this.localStructure.newOne) {
        // Se non era nuova, ripristino i vecchi valori
        this.initData(this.structure);
        this.flipped = false;
      } else {
        // Se era nuova, la elimino dalla lista del
        this.$emit("undo-structure", this.localStructure);
      }
    },
    newStructure() {
      /* Evento di creazione nuova struttura */
      this.$emit("new-structure");
    },
    selectStructure() {
      /* Evento di selezione struttura */
      this.$emit("select-structure", this.localStructure);
    },
    /* Esegue l'inizializzazione dei dati, deep copy in localStructure. */
    initData(rawStructure) {
      if (rawStructure.editing) {
        this.flipped = true;
        this.editing = true;
        this.valid = false;
      }
      let installDate = new Date(rawStructure.installDate);
      this.localStructure = JSON.parse(
        JSON.stringify({
          ...rawStructure,
          installDate: installDate,
        })
      );
    },
    getFormattedDate(date) {
      let installDate = new Date(date);
      let formattedDate = [installDate.getFullYear(), ("0" + (installDate.getMonth() + 1)).slice(-2), ("0" + installDate.getDate()).slice(-2)].join("-");
      return formattedDate;
    },
  },
  computed: {
    loraRegions() {
      return loraRegions;
    },
    /* Validation */
    validName() {
      return validate.validateStructName(this.localStructure.name);
    },
    validAddress() {
      return validate.validateStructAddress(this.localStructure.address);
    },
    validLat() {
      return validate.validateCoordinate(this.localStructure.coordinates.lat);
    },
    validLng() {
      return validate.validateCoordinate(this.localStructure.coordinates.lng);
    },
    validSpans() {
      return validate.validateStructSpans(this.localStructure.spans);
    },
    validForm() {
      return this.validName && this.validAddress && this.validLat && this.validLng && this.validSpans;
    },
    computedInstallDate: {
      set: function(val) {
        this.localStructure.installDate = val;
      },
      get: function() {
        return this.getFormattedDate(this.localStructure.installDate);
      },
    },
  },
  mounted() {
    if (!this.empty) {
      this.initData(this.structure);
    }
  },
  /* Quando cambia la struttura re-inizializzo il componente */
  watch: {
    structure: function(newValue) {
      this.initData(newValue);
    },
  },
};
</script>
<style scoped>
* {
  font-family: inherit;
  -webkit-touch-callout: text;
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}
select {
  margin: 0;
}
p {
  margin: 0;
  font-size: 0.9em;
}
h4 {
  margin: 0;
  font-weight: 500;
  color: rgb(150, 153, 162);
}
.bold {
  font-weight: 700;
  color: white;
}
#card {
  position: relative;
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  width: 350px;
  height: 480px;
  margin: 20px;
}
.back,
.front {
  position: absolute;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  height: 100%;
  width: 380px;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}
.selected-card {
  border: solid 3px white;
}
.empty-card {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  width: 380px;
  height: 440px;
  margin: 20px;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}
.empty-content {
  display: grid;
  width: 100%;
  height: calc(100% - 70px);
}
.plus-circle {
  align-self: center;
  justify-self: center;
  width: 50px;
}
.back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
  background-color: rgb(45, 48, 65);
  overflow: hidden;
  z-index: 2;
}
.front {
  background-color: rgb(45, 48, 65);
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
  z-index: 1;
}
.heading {
  display: flex;
  height: 25px;
  padding: 12px 20px;
  border-bottom: solid 2px rgb(80, 84, 105);
  font-size: 0.9em;
}
.content {
  padding: 20px;
}
.data-info {
  font-size: 0.8em;
  height: 40px;
  border-radius: 5px;
  width: 100%;
  margin-top: 4px;
  display: flex;
  overflow: hidden;
}
.data-label {
  margin-left: 15px;
  margin-top: 6px;
  width: 85%;
  color: rgb(150, 153, 162);
}
.data-value {
  color: white;
  margin-right: 15px;
  margin-top: 6px;
  font-weight: 700;
  white-space: nowrap;
}
.bridge-container > img {
  max-width: 100%;
  display: block; /* remove extra space below image */
  margin: auto;
}
.flip-container {
  display: inline-block;
  text-align: justify;
  height: fit-content;
}
.back.flipped {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
  -ms-transform: rotateY(0deg);
  -o-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.front.flipped {
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
  -ms-transform: rotateY(-180deg);
  -o-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  opacity: 0.5;
}
/* Rimuovo Arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
@keyframes flashflip {
  0% {
    opacity: 0;
    -webkit-transform: rotatey(-180deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotatey(+180deg);
  }
}
@-webkit-keyframes flashflip {
  0% {
    opacity: 0;
    -webkit-transform: rotatey(-180deg);
  }
  100% {
    opacity: 1;
    -webkit-transform: rotatey(+180deg);
  }
}

.form-wrap {
  display: grid;
  font-size: 0.7em;
}

.form-field {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  background-color: rgb(32, 34, 47);
  margin-bottom: 10px;
  height: 30px;
  border-radius: 10px;
  padding-left: 15px;
  box-sizing: border-box;
  width: 100%;
}

.form-field > label {
  padding-top: 5px;
  padding-bottom: 5px;
  font-weight: 500;
}
.form-field > input {
  border-width: 0px;
  border-radius: inherit;
  text-align: end;
  flex-direction: row-reverse;
  outline-width: 0;
  outline: none !important;
  color: white;
  font-weight: 700;
  background-color: inherit;
  text-align: end;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
.form-field > input[type="text"] {
  padding-right: 15px;
}
.form-field > input[type="date"] {
  padding-right: 15px;
}
.form-field > input[type="number"] {
  padding-right: 15px;
}
.form-field > input[type="checkbox"] {
  height: 18px;
  width: 18px;
  margin: auto 15px auto;
}
.form-button {
  margin-top: 5px;
  height: 30px;
}
.back-buttons-container {
  display: grid;
  height: 30px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-template-columns: 25% 20% 25% 5% 25%;
  padding: 0 20px 0 20px;
}
.front-buttons-container {
  width: 100%;
  display: grid;
  grid-template-columns: 30%;
  grid-template-rows: 32px;
  justify-content: center;
}
.button {
  font-weight: 600;
  outline-width: 0;
  outline: none !important;
  color: white;
  border-radius: 10px;
  border: solid 2px white;
  background-color: rgb(80, 84, 105);
  width: 100%;
  min-height: 30px;
}
.editing {
  background-color: rgb(22, 25, 35) !important;
}
.radius {
  border-radius: 50%;
}
.valid-field {
  border: solid 1.2px green !important;
}
.invalid-field {
  border: solid 1.2px red !important;
}
.horiz-divider {
  width: 100%;
  height: 2px;
  background-color: grey;
}
.red {
  background-color: red;
}
.inline-opts{
  display: flex;
  justify-content: space-between;
}
</style>
