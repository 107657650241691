<template>
  <div class="structures-list">
    <div class="heading"></div>
    <transition-group name="list-complete" tag="div" class="list-wrap">
      <FlipStruct
        class="list-complete-item"
        v-for="struct in structuresTemp"
        :key="struct._id"
        :structure="struct"
        :selected="getSelectedStructure && getSelectedStructure._id === struct._id"
        @save-structure="saveStructure"
        @delete-structure="deleteExsStructure"
        @undo-structure="undoNewStructure"
        @select-structure="selectNewStruct"
        @delete-old-events="deleteOldEventsByStruct"
      />
      <FlipStruct :empty="true" key="new_struct_ID" @new-structure="newStruct" />
    </transition-group>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FlipStruct from "./ui/FlipStruct.vue";

export default {
  name: "structures-list",
  components: {
    FlipStruct,
  },
  data() {
    return {
      structuresTemp: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      getStructures: "admin/getStructures",
      getSelectedStructure: "admin/getSelectedStructure",
    }),
    installDate: {
      get: function (oldValue) {
        return this.formatDate(oldValue);
      },
      set: function (newValue) {
        return new Date(newValue);
      },
    },
  },
  methods: {
    ...mapActions({
      insertStructure: "admin/insertStructure",
      selectStructure: "admin/selectStructure",
      editStructure: "admin/editStructure",
      deleteStructure: "admin/deleteStructure",
      deleteOldEvents: "admin/deleteOldEvents",
      setLoading: "admin/setLoadingStatus",
    }),
    async saveStructure(structure) {
      this.setLoading(true);
      let tempStruct = this.structuresTemp.find((a) => a._id === structure._id); // Struttura in locale
      let exsisting = this.getStructures.find((a) => a._id === structure._id); // Struttura dall'API
      if (exsisting) {
        // Se sto aggiornando una struttura già esistente
        await this.editStructure(structure);
      } else {
        // Se ne sto creando una nuova
        this.loading = true;
        delete structure._id;
        await this.insertStructure(structure);
        this.loading = false;
      }
      //this.insertStructure(structure);
      tempStruct.editing = false;
      this.setLoading(false);
    },
    async deleteExsStructure(structure) {
      this.setLoading(true);
      let exsisting = this.getStructures.find((a) => a._id === structure._id); // Se è una struttura esistente
      if (exsisting) {
        if (confirm("Rimuovere la struttura? Azione irreversibile.")) {
          await this.deleteStructure(structure);
        }
      }
      this.setLoading(false);
    },
    async deleteOldEventsByStruct(structure) {
      this.setLoading(true);
      let exsisting = this.getStructures.find((a) => a._id === structure._id); // Se è una struttura esistente
      if (exsisting) {
        if (confirm("Rimuovere TUTTI i dati precedenti ad installDate? Azione irreversibile.")) {
          await this.deleteOldEvents(structure);
        }
      }
      this.setLoading(false);
    },

    undoNewStructure(structure) {
      let myLocalStruct = this.structuresTemp.find((a) => a._id === structure._id);
      let index = this.structuresTemp.indexOf(myLocalStruct);
      if (index > -1) {
        this.structuresTemp.splice(index, 1);
      }
    },
    selectNewStruct(structure) {
      this.selectStructure(structure._id);
    },
    checkValidInput(e, struct) {
      let valid = e.target.checkValidity();
      struct.valid = valid && struct.valid;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    reset(struct) {
      this.structuresTemp = JSON.parse(JSON.stringify(this.structures));
      struct.editing = false;
    },
    save(struct) {
      struct.editing = false;
    },
    edit(struct, index) {
      struct.editing = !struct.editing;
      setTimeout(() => {
        this.$refs[index + "name"][0].focus();
      }, 0);
    },
    newStruct() {
      if (!this.structuresTemp.some((a) => a.editing)) {
        this.structuresTemp.push({
          _id: "newid",
          name: "",
          spans: 2,
          installDate: new Date(),
          loraRegion: "EU863-870",
          address: "",
          coordinates: {
            lat: 55.12,
            lng: 54.23,
          },
          type: "generic",
          fddEnabled: false,
          railEnabled: false,
          overlaysEnabled: true,
          editing: true,
          valid: false,
          newOne: true,
        });
      }
    },
  },
  mounted() {
    this.structuresTemp = JSON.parse(
      JSON.stringify(
        this.getStructures.map((x) => ({
          ...x,
          editing: false,
          valid: true,
        }))
      )
    );
  },
  watch: {
    getStructures: {
      handler: function (newValue) {
        this.structuresTemp = JSON.parse(JSON.stringify(newValue));
        if (newValue[0]) this.selectStructure(newValue[0]._id)
        else this.selectStructure(null)
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
* {
  font-family: inherit;
}
.structures-list {
  width: 100%;
  overflow: hidden;
}
.list-wrap {
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  background-color: rgb(22, 25, 35);
  overflow-x: scroll;
  overflow-y: hidden;
}
.loading-wrap {
  height: 393px;
  width: 100%;
}
.heading {
  height: fit-content;
  background-color: rgb(22, 25, 35);
}
.empty-struct {
  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -o-perspective: 1000;
  perspective: 1000;
  width: 450px;
  height: 400px;
  margin: 20px;
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  border: dashed 0.1em rgb(80, 84, 105);
  border-radius: 10px;
}
h2 {
  margin-bottom: 20px;
  text-align: left;
}
h1 {
  padding: 20px;
  margin: 0;
  font-size: 1em;
  text-align: left;
}
.form-wrap {
  text-align: left;
  display: grid;
}

.form-wrap > label {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.9em;
  font-weight: 500;
  grid-column: 1;
}
.form-wrap > input {
  border-width: 0px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
  padding-left: 5px;
  grid-column: 2;
  outline-width: 0;
  outline: none !important;
  color: rgb(198, 198, 198);
  background-color: rgb(80, 84, 105);
}

.buttons-container {
  display: grid;
  height: 30px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-template-columns: 25% 20% 25% 5% 25%;
  margin-top: 35px;
}
.buttons-container > button {
  font-weight: 700;
  outline-width: 0;
  outline: none !important;
  color: white;
  border-radius: 10px;
  border: solid 2px white;
  background-color: rgb(80, 84, 105);
}
input:enabled {
  text-align: center;
  background-color: rgb(22, 25, 35) !important;
}
.enabled {
  text-align: center;
  background-color: rgb(22, 25, 35) !important;
  border: solid 1.2px green !important;
}
.plus {
  height: 337px;
  width: 90%;
  opacity: 0.8;
  display: inline-block;
  background: linear-gradient(grey, grey) top left, linear-gradient(grey, grey) top right, linear-gradient(grey, grey) bottom left,
    linear-gradient(grey, grey) bottom right;
  background-size: calc(50% - 1px) calc(50% - 1px); /*thickness = 2px (2*1px) */
  background-repeat: no-repeat;
  box-sizing: border-box;
  background-position: center;
  background-size: 50% 3px, 3px 50%; /*thickness = 2px, length = 50% (25px)*/
  background-repeat: no-repeat;
}

.radius {
  border-radius: 50%;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: rgb(45, 48, 65);
}

::-webkit-scrollbar {
  width: 12px;
  background-color: rgb(45, 48, 65);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: rgb(80, 84, 105);
}

input:invalid {
  border: solid 1.2px red;
  padding: 1px;
}
input:valid {
  border: solid 1.2px green;
  padding: 1px;
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}

.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>
