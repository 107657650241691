<template>
  <div :class="[selected ? 'selected-gw' : '', 'gw noselect-hard']">
    <header @click="$emit('select')">
      <h3>{{ device._id }}</h3>
      <span :class="[device.connected ? 'green-dot' : '', 'dot']"></span>
    </header>
    <main>
      <div class="gw-info" @click="$emit('select')">
        <h4>{{ device.basename + " " + device.modelname }}</h4>
      </div>
      <img :src="gwImage" @click="$emit('select')" />
      <div id="vn-switch">
        <p>Virtual Network:</p>
        <label class="switch"
          ><input type="checkbox" v-model="device.virtualNetworkEnabled" @change="$emit('changed')" :disabled="!virtualNetworkFeature"/>
          <span class="slider round"></span>
        </label>
      </div>
    </main>
  </div>
</template>
<script>
/* Singolo Gateway all'interno di una struttura. */
export default {
  name: "gateway-card",
  props: {
    value: Object, // Oggetto gateway
    selected: Boolean, // True se selezionato
    virtualNetworkFeature: { // True se ho l'opzione della VN
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      device: {}, // Oggetto gateway
    };
  },
  // Plotto immagine diversa a seconda del modello
  computed: {
    gwImage() {
      if (this.device.basename === "Packet Forwarder") {
        return require("../../../../public/assets/pktfwd-semtech.png");
      }
      return require("../../../../public/assets/rak-7249.png");
    },
  },
  // V-Model watcher
  watch: {
    device: {
      handler: function() {
        this.$emit("input", this.device);
      },
      deep: true,
    },
  },
  mounted() {
    // Deep copy
    this.device = JSON.parse(JSON.stringify(this.value));
  },
};
</script>
<style scoped>
header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
main {
  padding: 10px;
  padding-top: 0px;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
img {
  max-height: 80px;
  margin: 0 auto;
  image-rendering: -webkit-optimize-contrast;
  transform: translateZ(0);
}
.gw {
  background-color: rgb(45, 48, 65);
  border-radius: 10px;
  min-width: 200px;
  position: relative;
  cursor: pointer;
  margin: 6px;
  padding: 6px;
}
h3 {
  margin: 0px;
  font-size: 0.8em;
}
h4 {
  font-size: 0.6em;
}
p {
  margin: 0;
}
h4,
h5 {
  padding: 0;
  margin: 0;
}
.selected-gw {
  background-color: rgba(33, 150, 243, 0.8);
}
.gw-info {
  height: fit-content;
}
#vn-switch {
  display: flex;
  margin-top: 16px;
}
.switch {
  margin-left: 6px;
}
.dot {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
}
.green-dot {
  background-color: green;
}
</style>
