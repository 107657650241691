<i18n>
{
  "en": {
    "add": "Add",
    "undo": "Undo",
    "save": "Save",
    "delete": "Delete selected GW",
    "addGwToStructure": "Add a gateway to the structure"
  },
  "it": {
    "add": "Aggiungi",
    "undo": "Annulla",
    "save": "Salva",
    "delete": "Elimina GW selezionato",
    "addGwToStructure": "Aggiungi un gateway alla struttura"
  }
}
</i18n>

<template>
  <div class="root">
    <header>
      <h2>Gateway:</h2>
    </header>
    <main>
      <!-- Controlli -->
      <div class="extra-control">
        <!-- Admin Settings -->
        <button class="rounded-button green" :disabled="!selectedStructure" @click="showGatewaySearch = true">{{ $t("add") }}</button>
        <button :disabled="!unsavedChanges" class="rounded-button" @click="undo">{{ $t("undo") }}</button>
        <button :disabled="!unsavedChanges" class="rounded-button orange" @click="putGws">{{ $t("save") }}</button>
        <button :disabled="!selectedGatewayID" class="rounded-button red" @click="deleteGW">{{ $t("delete") }}</button>
      </div>
      <GatewaySearch v-if="showGatewaySearch && lockedRegion !== undefined" @close="showGatewaySearch = false" :lockedRegion="lockedRegion" />
      <div class="gws-wrapper">
        <div v-if="gws.length === 0" class="empty-gateway-msg">
          <h3>{{ $t("addGwToStructure") }}...</h3>
        </div>
        <GatewayCard
          v-for="gw in gws"
          :value="gw"
          :key="gw._id + componentKey"
          @changed="unsavedChanges = true"
          @select="selectGw(gw._id)"
          :selected="selectedGatewayID === gw._id"
          :virtualNetworkFeature="vnFeature"
          @input="updateGW"
        />
      </div>
    </main>
    <footer><div class="actions"></div></footer>
  </div>
</template>
<script>
/* Lista dei gateway assegnati ad una struttura. */
import GatewaySearch from "../../ui/GatewaySearch.vue";
import GatewayCard from "./GatewayCard.vue";
export default {
  name: "gateway-list",
  components: {
    GatewaySearch,
    GatewayCard,
  },
  props: {
    gateways: Array, // Lista di gateway della struttura
    selectedStructure: Object, // Oggetto struttura selezionata
    lockedRegion: String, // Costringo alla ricerca in questa region
  },
  data() {
    return {
      showGatewaySearch: false, // Mostra il modale di inserimento gateway da inventario
      unsavedChanges: false, // Modifiche non salvate
      selectedGatewayID: null, // ID (gw_eui) del gateway selezionato
      gws: [], // Lista dei gateway della struttura
      componentKey: 0,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
    updateGW(gw) {
      for (let i = 0; i < this.gws.length; i++) {
        if (this.gws[i]._id === gw._id) {
          this.$set(this.gws, i, gw);
        }
      }
    },
    // Seleziona un gateway in base il suo ID (deseleziona se già selezionato)
    selectGw(gwID) {
      if (this.selectedGatewayID === gwID) {
        this.selectedGatewayID = null;
      } else {
        this.selectedGatewayID = gwID;
      }
    },
    // Emette un evento contenente la lista di gateway editata
    putGws() {
      const sendGwList = JSON.parse(JSON.stringify(this.gws));
      // Elimino il campo "connected" prima di fare la richiesta
      sendGwList.map((gw) => {
        delete gw.connected;
      });
      this.$emit("putgateways", sendGwList);
      this.unsavedChanges = false;
    },
    // Emette un evento contenente l'ID del gateway da eliminare
    deleteGW() {
      if (this.selectedGatewayID && confirm("ATTENZIONE: Eliminare il Gateway dalla Struttura?")) {
        this.$emit("deletegateway", this.selectedGatewayID);
        this.gws = this.gws.filter((gw) => gw._id !== this.selectedGatewayID);
        this.selectedGatewayID = null;
      }
    },
    // Annulla le modifiche eseguendo una deep-copy di nuovo forzando il rendering delle gateway-cards
    undo() {
      this.gws = JSON.parse(JSON.stringify(this.gateways));
      this.forceRerender();
      this.unsavedChanges = false;
    },
  },
  computed: {
    // True se il cliente ha la virtual network settata sulla struttura
    vnFeature() {
      return this.selectedStructure && this.selectedStructure.virtualNetworkID !== undefined;
    },
    // True se il GW non è RAK o MIKROTIK
    customGateway() {
      const selectedGatewayObject = this.gws.find((gw) => gw._id === this.selectedGatewayID);
      return selectedGatewayObject && !["Packet Forwarder", "RAK"].includes(selectedGatewayObject.basename);
    },
  },
  watch: {
    // Al cambiare del props con i gateway effettuo deep-copy e seleziono il primo disponibile (se c'è)
    gateways: function(newValue) {
      if (newValue) {
        this.gws = JSON.parse(JSON.stringify(newValue));
        this.selectedGatewayID = this.gws[0] ? this.gws[0]._id : null;
      }
    },
  },
};
</script>
<style scoped>
h2 {
  font-size: 1em;
}
main {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
}
.empty-gateway-msg {
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
}
.root {
  text-align: left;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
  position: relative;
  background-color: rgb(45, 48, 65);
  width: 96%;
  min-width: 400px;
  margin: auto;
  height: fit-content;
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
}
.gws-wrapper {
  background-color: rgb(16, 17, 19);
  margin: 15px;
  border-radius: 10px;
  position: relative;
  display: flex;
  min-height: 200px;
  overflow-x: scroll;
}
.extra-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-grid-row: 1;
  grid-row: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 60px;
  align-self: center;
  margin-left: 15px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.extra-control button {
  margin-right: 16px;
}
.green {
  background-color: green;
}
.orange {
  background-color: orange;
}
.red {
  background-color: red;
}
</style>
