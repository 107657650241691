<template>
  <div class="topnav noselect">
    <!-- <img
      id="sidebar"
      class="img-button"
      src="../../../public/assets/icons/sidebar.svg"
      @click="groupsToggle"
    /> -->
    <!-- <select id="type" v-model="actualUser" class="select-options">
      <option :value="user" v-for="(user, index) in filteredList" :key="index">{{ user.username }}</option>
    </select> -->
    <v-select id="searchcustomer" class="generic-select" :options="filteredList" label="label" v-model="actualUser"></v-select>
    <img id="editcustomer" class="img-button" src="../../../public/assets/icons/edit-customer.svg" @click="editCustomer" />
    <img id="addcustomer" class="img-button" src="../../../public/assets/icons/add-customer.svg" @click="addCustomer" />
    <button v-if="selectedUser" id="loginas" type="button" class="std-button" @click="loginAsUser" v-on:click.stop>Login as {{ selectedUser.username }}</button>
    <img id="logo" src="../../../public/assets/MoveDashLogo.svg" />
    <div id="usage-block" v-if="nsUsage">
      <p>
        GW: <span class="strong">{{ nsUsage.gatewayUse }}/{{ nsUsage.gatewayLimit }}</span>
      </p>
      <p>
        DEV: <span class="strong">{{ nsUsage.deviceUse }}/{{ nsUsage.deviceLimit }}</span>
      </p>
      <p>
        MCAST_DEV: <span class="strong">{{ nsUsage.multicastDeviceUse }}/{{ nsUsage.multicastDeviceLimit }}</span>
      </p>
    </div>
    <div id="language-block">
      <img id="log-out" src="../../../public/assets/icons/ita.svg" @click="setLanguage('it')" />
      <img id="log-out" src="../../../public/assets/icons/eng.svg" @click="setLanguage('en')" />
    </div>
    <div id="exit-block" @click="logout">
      <p>Exit</p>
      <img id="log-out" src="../../../public/assets/icons/log-out.svg" />
    </div>
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import { mapGetters, mapActions } from "vuex";
import router from "../../router";

export default {
  name: "nav-bar-admin",
  data() {
    return {
      showCustomerMenu: true,
      search: "",
    };
  },
  props: {
    userList: Array,
    selectedUser: Object,
  },
  computed: {
    ...mapGetters({
      nsUsage: "admin/getNsUsage",
    }),
    filteredList() {
      return this.userList
        .filter((user) => {
          return user.type === "standard";
        })
        .map((usr) => {
          return { ...usr, label: usr.username + " (" + (usr.networkServer === "loriot" ? "public" : "private") + ")" };
        });
    },
    actualUser: {
      // getter
      get: function() {
        return { ...this.selectedUser, label: this.selectedUser && this.selectedUser.username + " (" + (this.selectedUser.networkServer === "loriot" ? "public" : "private") + ")" };
      },
      // setter
      set: function(newValue) {
        if (newValue) {
          this.$emit("select-customer", newValue._id);
        }
      },
    },
  },
  methods: {
    ...mapActions({
      loginAs: "admin/loginAs",
    }),
    async loginAsUser() {
      console.log(this.selectedUser.username);
      await this.loginAs(this.selectedUser.username);
      router.push("dashboard");
    },
    logout: function() {
      this.$emit("logout");
    },
    groupsToggle: function() {
      this.$emit("groups-toggle");
    },
    setLanguage(lang) {
      this.$root.$i18n.locale = lang;
    },
    addCustomer() {
      this.$emit("add-customer");
    },
    editCustomer() {
      this.$emit("edit-customer");
    },
  },
};
</script>
<style scoped>
/* Add a color to the active/current link */
p {
  color: white;
}
.topnav a.active {
  background-color: #4caf50;
  color: white;
}

.link-wrapper {
  height: 15px;
}
.topnav {
  background-color: rgb(40, 42, 59);
  border-bottom: solid 1px rgb(80, 84, 105);
  height: 50px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  /* font-weight: 600; */
  /* font-size: 1em; */
  align-items: center;
  z-index: 8;
  display: grid;
  overflow: initial;
  grid-template-columns: minmax(280px, 10%) max-content max-content max-content 10fr max-content max-content max-content;
  padding-left: 20px;
  box-sizing: border-box;
}
#logo {
  justify-self: center;
  grid-row: 1;
  grid-column: 5;
}
.img-button {
  padding: 8px;
  width: 50px;
}
.router-link-active {
  background-color: rgb(57, 60, 80);
}

.link {
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 35px;
  margin-right: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#searchcustomer {
  grid-row: 1;
  grid-column: 1;
}
#editcustomer {
  grid-row: 1;
  grid-column: 2;
}
#addcustomer {
  grid-row: 1;
  grid-column: 3;
}
#exit-block {
  display: flex;
  align-items: center;
  cursor: pointer;
  grid-row: 1;
  grid-column: 8;
  margin: 0 20px;
}
#exit-block > img {
  height: fit-content;
}
#exit-block > p {
  margin: 0;
  margin-right: 10px;
  font-weight: 600;
  font-size: 0.8em;
}
#usage-block {
  grid-row: 1;
  grid-column: 6;
  display: flex;
  margin: 0 20px;
}
#usage-block > p {
  margin: 0;
  font-size: 0.8em;
}
#usage-block > p > span {
  margin: 0 5px 0 0;
  font-size: 0.9em;
  font-weight: 700;
}
#language-block {
  grid-row: 1;
  grid-column: 7;
  margin: 0 20px;
}
#language-block > img {
  height: 25px;
  padding: 5px;
  cursor: pointer;
}
#loginas {
  margin-left: 16px;
  grid-row: 1;
  grid-column: 4;
}
.vert-divider {
  margin-right: 3vw;
  width: 2px;
  height: 40px;
  background-color: rgba(80, 84, 105, 1);
  position: relative;
  justify-self: center;
}

.vs__fade-enter-active,
.vs__fade-leave-active {
  transition: none;
}

h1,
.muted {
  color: #2c3e5099;
}

h1 {
  font-size: 26px;
  font-weight: 600;
}
</style>
