<template>
  <div class="outer">
    <!-- Wrapper -->
    <div class="psw-reset" @keyup.enter="changePsw">
      <form id="psw-form" autocomplete="off">
        <!-- Input 1 -->
        <label for="fname">Nuova Password:</label>
        <input type="password" id="psw" name="psw" ref="mainpsw" :class="[this.validPsw ? 'valid-field' : 'invalid-field']" v-model="mainPsw" autofocus />
        <!-- Input 2 -->
        <label for="fname">Conferma Password:</label>
        <input type="password" id="psw-confirm" name="psw-confirm" ref="psw-confirm" :class="[this.equalPsw && this.validPsw ? 'valid-field' : 'invalid-field']" v-model="confirmPsw" autofocus />
        <!-- Errors -->
        <div>
          <p :class="[errors ? 'errors' : 'messages', 'info']">
            {{ errors || messages }}
          </p>
        </div>
        <!-- Buttons -->
        <div class="btns">
          <button type="button" class="button" @click="undoChange">
            Annulla
          </button>
          <button type="button" class="button" @click="changePsw" :disabled="!validPsw || !equalPsw">Conferma</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import validate from "../../../helpers/validate";

export default {
  name: "psw-reset",
  data() {
    return {
      mainPsw: "",
      confirmPsw: "",
    };
  },
  methods: {
    changePsw() {
      // Se la pws è valida e coincidono emetto l'evento
      if (this.validPsw && this.equalPsw) {
        this.$emit("change-password", this.confirmPsw);
      }
    },
    undoChange() {
      // Annulla la modifica della psw
      this.$emit("undo");
    },
  },
  computed: {
    validPsw() {
      // Valida la password nell'input 1
      return validate.validatePassword(this.mainPsw);
    },
    equalPsw() {
      // Verifica che pws coincidano
      return this.mainPsw === this.confirmPsw;
    },
    errors() {
      // Requisiti psw
      const missing = "Minimo 7 caratteri. Almeno un numero ed un carattere speciale.";
      // Coincidenza psq
      const notEqual = "Le password non corrispondono!";
      // Errore risultante
      let computedError = !this.validPsw ? missing : !this.equalPsw ? notEqual : null;
      return computedError;
    },
    messages() {
      // Messaggio di successo
      const pswOk = "Le password corrispondono!";
      let computedMsg = this.validPsw && this.equalPsw ? pswOk : null;
      return computedMsg;
    },
  },
  mounted() {
    // Appena apro il modale setto il focus sul campo principale
    setTimeout(() => {
      this.$refs["mainpsw"].focus();
    }, 0);
  },
};
</script>
<style scoped>
.outer {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 12;
  background-color: rgba(0, 0, 0, 0.3);
}

.info {
  font-size: 0.9em;
  font-family: inherit;
  font-weight: 700;
  white-space: normal;
}

.errors {
  color: rgb(255, 0, 0);
}

.messages {
  color: green;
}

#psw-form {
  display: grid;
  grid-template-rows: repeat(4, 14%) 29% 15%;
  justify-content: center;
  align-items: center;
  grid-template-columns: 100%;
  font-size: 0.7em;
  white-space: nowrap;
  height: 100%;
}

#psw-form > label {
  font-weight: 700;
  color: rgb(150, 153, 162);
}
#psw-form > input {
  height: 80%;
  border-width: 0px;
  border-radius: 5px;
  outline-width: 0;
  padding-left: 10px;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47) !important;
}
.psw-reset {
  width: 340px;
  height: 240px;
  border-radius: 10px;
  padding: 20px;
  box-sizing: border-box;
  border: solid 0.1em white;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-sizing: border-box;
}
.btns {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.button {
  width: 40%;
  height: 100%;
  line-height: 100%;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(47, 112, 177);
  border: solid 2px white;
  border-radius: 7px;
  white-space: nowrap;
  transition: transform 0.1s linear;
}
.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.valid-field {
  border: solid 1.2px green !important;
}
.invalid-field {
  border: solid 1.2px red !important;
}
</style>
