<template>
  <div class="outer-wrap" v-if="this.userTemp" @click="closeMe">
    <div class="user-info" @click.stop>
      <!-- Header -->
      <div id="user-header" class="noselect c1">
        <h1>{{ !user ? "Aggiungi Cliente" : "Modifica Cliente" }}</h1>
        <a @click="closeMe" class="close"></a>
      </div>

      <!-- Wrapper Form -->
      <div class="form-wrap">
        <!-- Form Header -->
        <div class="form-header">
          <h5>Dati cliente</h5>
        </div>
        <!-- Username -->
        <label for="user">Username:</label>
        <input
          v-if="!user"
          type="text"
          id="user"
          name="user"
          ref="username"
          :class="[this.validName ? 'valid-field' : 'invalid-field']"
          v-model.trim="userTemp.username"
          spellcheck="false"
          :disabled="!editing"
        />
        <h5 v-else class="static-h">{{ userTemp.username }}</h5>
        <!-- Password -->
        <label for="psw-reset">Password:</label>
        <transition name="settings">
          <PswReset v-if="pswEditing" @change-password="pswChange" @undo="undoPswChange" />
        </transition>
        <button class="button embed-btn" id="psw-reset" type="button" @click="resetPassword" :class="[this.user || userTemp.password ? 'valid-field' : 'invalid-field']">
          {{ !user ? (userTemp.password ? "Cambia" : "Inserisci") : "Resetta" }}
        </button>
        <!-- Abbonamento -->
        <label for="type">Abbonamento:</label>
        <select id="type" v-model="userTemp.type" class="select-options">
          <option :value="type" v-for="(type, index) in customerTypes" :key="index">{{ type }}</option>
        </select>
        <!-- Network Server -->
        <label for="type">Network Server:</label>
        <select id="type" v-model="userTemp.networkServer" class="select-options">
          <option :value="ns" v-for="(ns, index) in networkServers" :key="index">{{ ns }}</option>
        </select>
      </div>

      <!-- Email Associate -->
      <div class="form-wrap c2">
        <!-- Form Header -->
        <div class="form-header">
          <h5>Dati cliente</h5>
        </div>
        <!-- Lora userID -->
        <label for="userID">userID:</label>
        <input type="text" id="lorauserid" name="userID" :class="'valid'" placeholder="Auto-generated..." v-model.trim="userTemp.userID" spellcheck="false" :disabled="true" />
        <!-- Lora Roaming ID -->
        <!--  <label for="roamingID">Roaming ID:</label> -->
        <!-- <input type="text" id="loraroamingid" name="roamingID" :class="'valid'" v-model.trim="userTemp.roamingID" spellcheck="false" :disabled="!editing" /> -->
        <!-- virtualNetwork -->
        <!-- <label for="roamingID">Virtual Network:</label> -->
        <!-- <input type="number" id="virtualNetwork" name="virtualNetwork" :class="'valid'" v-model.number="userTemp.virtualNetwork" spellcheck="false" :disabled="!editing" /> -->
        <!-- Lora APPID -->
        <label for="APPID">Lora APPID:</label>
        <input type="text" id="loraappid" name="APPID" :class="[userTemp.loraAppID ? 'valid-field' : '']" placeholder="Auto-generated..."  v-model.trim="userTemp.loraAppID" spellcheck="false" :disabled="true" />
        <!-- Lora Token -->
        <label for="loratoken">Lora Token:</label>
        <input type="text" id="loratoken" name="loratoken" :class="[userTemp.loraToken ? 'valid-field': '']" placeholder="Auto-generated..."  v-model.trim="userTemp.loraToken" spellcheck="false" :disabled="true" />
        <!-- API TOKEN (solo per utenti esistenti) -->
        <label v-if="this.user" for="copy-token">API TOKEN:</label>
        <button v-if="this.user" class="button embed-btn" id="copy-token" type="button" @click="copyTokenToClipboard">Copia TOKEN</button>
      </div>
      <!-- Footer -->
      <div id="footer">
        <div class="footer-btn">
          <button v-if="this.user" type="button" class="button c1" @click="deleteUser">Elimina Cliente</button>
          <button type="button" class="button c4" @click="closeMe">
            {{ $t("message.undo") }}
          </button>
          <button type="button" class="button save c5" :disabled="!validForm" @click="saveSettings">
            {{ $t("message.saveChanges") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import validate from "../../../helpers/validate";
import copyToClipboard from "../../../helpers/clipboard";
import PswReset from "./ResetPassword.vue";

export default {
  name: "user-info",
  components: {
    PswReset,
  },
  props: {
    user: Object, // Quando è presente considero il tutto come un 'modifica cliente'
  },
  data() {
    return {
      pswEditing: false,
      userTemp: {},
      editing: true,
      customerTypes: ["free", "standard", "premium"],
      networkServers: ["loriot", "loriotMove"],
    };
  },
  methods: {
    saveSettings() {
      if (this.user) {
        // Sto modificando un utente esistente
        this.updateUser();
      } else {
        // Sto inserendo un nuovo utente
        this.insertNewUser();
      }
    },
    async deleteUser() {
      if (confirm("Eliminare il cliente ed i relativi dati? Azione irreversibile!")) {
        this.$emit("delete-user", this.user._id);
        this.closeMe();
      }
    },
    updateUser() {
      let updateInfo = {
        loraAppID: this.userTemp.loraAppID,
        loraToken: this.userTemp.networkServer != "loriot" && this.userTemp.networkServer != "loriotMove" ? null : this.userTemp.loraToken,
        networkServer: this.userTemp.networkServer,
        type: this.userTemp.type,
      };
      if (this.userTemp.password) {
        updateInfo.password = this.userTemp.password;
      }
      if (this.userTemp.userID != undefined) {
        updateInfo.userID = this.userTemp.userID;
      }
      if (this.userTemp.virtualNetwork != undefined) {
        updateInfo.virtualNetwork = this.userTemp.virtualNetwork;
      }
      if (this.userTemp.roamingID != undefined) {
        updateInfo.roamingID = this.userTemp.roamingID;
      }
      this.$emit("update-customer", { customerID: this.userTemp._id, updateInfo: updateInfo });
      this.closeMe();
    },
    insertNewUser() {
      this.$emit("add-customer", this.userTemp);
      this.closeMe();
    },
    copyTokenToClipboard: function() {
      copyToClipboard(this.user.API_Token);
    },
    resetPassword: function() {
      this.pswEditing = true;
    },
    pswChange(psw) {
      this.$set(this.userTemp, "password", psw);
      this.pswEditing = false;
    },
    undoPswChange() {
      this.pswEditing = false;
    },
    closeMe() {
      this.$emit("close-userinfo");
    },
  },
  mounted() {
    if (this.user) {
      this.userTemp = JSON.parse(JSON.stringify(this.user)); // Deep Copy
    } else {
      // Valori di default e focus su username
      this.$set(this.userTemp, "type", "standard");
      this.$set(this.userTemp, "networkServer", "loriotMove");
      setTimeout(() => {
        this.$refs["username"].focus();
      }, 0);
    }
  },
  computed: {
    /* Validation */
    validName() {
      return validate.validateUsername(this.userTemp.username);
    },
    /* validAPPID() {
      if (this.userTemp.networkServer === "loriot" || this.userTemp.networkServer === "loriotMove") {
        return validate.validateLoraAppID(this.userTemp.loraAppID);
      } else {
        return validate.validateGenericAppID(this.userTemp.loraAppID);
      }
    }, */
    /* validLoraToken() {
      return validate.validateLoraToken(this.userTemp.loraToken) || (this.userTemp.networkServer != "loriot" && this.userTemp.networkServer != "loriotMove");
    }, */
    validPassword() {
      return validate.validatePassword(this.userTemp.password);
    },
    validForm() {
      return this.validName /* && this.validAPPID && this.validLoraToken */ && (this.user || this.validPassword);
    },
  },
  watch: {
    user: function(newValue) {
      this.userTemp = JSON.parse(JSON.stringify(newValue)); // Deep Copy
    },
  },
};
</script>
<style scoped>
* {
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  color: white;
  -webkit-touch-callout: text; /* iOS Safari */
  -webkit-user-select: text; /* Safari */
  -khtml-user-select: text; /* Konqueror HTML */
  -moz-user-select: text; /* Old versions of Firefox */
  -ms-user-select: text; /* Internet Explorer/Edge */
  user-select: text; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}
.dark-overlay {
  background-color: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100%;
  position: absolute;
}
body {
  background-color: white !important;
}
h1 {
  font-size: 1em;
}
h5 {
  margin: 0;
  font-weight: 700;
}
p {
  font-size: 0.8em;
  color: rgb(198, 198, 198);
}
.user-info {
  /* width: 40vw; */
  height: fit-content;
  min-width: 840px;
  min-height: 400px;
  top: 22vh;
  left: 0;
  position: sticky;
  grid-template-rows: 10% 78% 12%;
  border-radius: 10px;
  overflow: hidden;
  display: grid;
  background-color: rgb(45, 48, 65);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
}
#footer {
  background-color: rgb(45, 48, 65);
  border-top: solid 0.8px gray;
  height: 100%;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 2;
  -webkit-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 1px -8px 10px 0px rgba(0, 0, 0, 0.4);
}

.close {
  align-self: center;
  cursor: pointer;
  right: 2%;
  width: 3%;
}
.outer-wrap {
  z-index: 16;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.form-wrap {
  width: 100%;
  text-align: left;
  display: grid;
  padding: 40px;
  box-sizing: border-box;
  grid-row: 2;
  grid-template-rows: 60px auto;
  height: fit-content;
}
.side-form-wrap {
  width: 100%;
  text-align: left;
  display: grid;
  padding: 40px;
  box-sizing: border-box;
  grid-row: 2;
  grid-template-rows: 60px auto;
}
.mails-container {
  height: 240px;
  grid-column-start: 1;
  grid-column-end: 3;
  border-radius: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  background: rgb(22, 25, 35);
  display: grid;
}

.mails-container > h5 {
  justify-self: center;
  align-self: center;
  font-style: italic;
}

.mail {
  height: 60px;
  width: 100%;
  display: grid;
  grid-template-columns: 80% 20%;
  grid-template-rows: 50% 50%;
  padding: 10px;
  box-sizing: border-box;
}

.mail > p {
  margin: 0;
  font-size: 0.65em;
  color: white;
  align-items: center;
}

div.mail:nth-child(even) {
  background: rgba(45, 48, 65, 0.6);
}

div.mail:nth-child(odd) {
  background: rgb(32, 34, 47);
}

.form-header {
  width: 100%;
  border-bottom: solid 1px rgb(80, 84, 105);
  grid-column-start: 1;
  grid-column-end: 3;
  margin-bottom: 20px;
}

#user-header {
  background-color: rgb(45, 48, 65);
  height: 100%;
  min-height: 45px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row: 1;
  z-index: 11;
  display: flex;
  align-items: center;
  padding-left: 1.2vw;
  border-bottom: solid 2px white;
}
.form-wrap > label {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 0.7em;
  font-weight: 700;
  grid-column: 1;
  text-align: right;
  align-self: center;
  color: rgb(150, 153, 162);
}
.form-wrap > input {
  font-size: 0.7em;
  /*   border-width: 0px; */
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  padding-left: 5px;
  grid-column: 2;
  outline-width: 0;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47) !important;
}

.static-h {
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  padding-left: 5px;
}

.buttons-container {
  display: grid;
  height: 30px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-template-columns: 25% 20% 25% 5% 25%;
  margin-top: 35px;
}
.buttons-container > button {
  font-weight: 700;
  outline-width: 0;
  outline: none !important;
  color: white;
  border-radius: 10px;
  border: solid 2px white;
  background-color: rgb(80, 84, 105);
}

/* Control Buttons */
.footer-btn {
  display: grid;
  grid-template-columns: repeat(5, 20%);
  width: 100%;
  padding: 0 40px 0 40px;
}
.button {
  width: fit-content;
  height: 30px;
  font-size: 0.8em;
  padding: 2%;
  line-height: 100%;
  margin: auto;
  font-weight: 600;
  margin-right: 20px;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  border-radius: 7px;
  white-space: nowrap;
  transition: transform 0.1s linear;
}

.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}
.button:disabled {
  background-color: grey;
}
.button:disabled:hover {
  background-color: grey;
}
.embed-btn {
  width: auto;
  box-sizing: border-box;
  height: 28px;
  border: solid 1.5px grey;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 0px;
  padding-left: 5px;
  grid-column: 2;
  outline-width: 0;
  line-height: 1em;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47);
}
/* .buttons-container > #reset{
	grid-column: 1;
}
.buttons-container > #edit{
	grid-column: 3;
}*/
.buttons-container > #save {
  background-color: rgb(21, 146, 230);
}
.select-options {
  border-width: 0px;
  font-size: 0.7em;
  border-radius: 5px;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  padding-left: 5px;
  grid-column: 2;
  outline-width: 0;
  outline: none !important;
  color: white;
  background-color: rgb(32, 34, 47) !important;
}
.valid-field {
  border: solid 1.2px green;
}
.invalid-field {
  border: solid 1.2px red;
}
.static-field {
  background-color: initial !important;
}
.form-wrap > input:disabled {
  border: hidden;
}

::-webkit-scrollbar {
  width: 0.3em;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(80, 84, 105);
  outline: 1px solid slategrey;
  border-radius: 10px;
}
</style>
