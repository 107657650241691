<template>
  <!-- Bridge img -->
  <div class="bridge-parent">
    <SensorInfo
      v-if="showSensorEditing && this.selectedSensor"
      :disabledSensorTypeSelect="true"
      @close="showSensorEditing = false"
      @delete-sensor="deleteSensor"
      @add-sensor="insertNewSensor"
      @update-sensor="updateSensor"
      @reset-sensor-stats="resetSensorStats"
      :sensor="this.selectedSensor"
      :newPosition="this.selectedPosition"
      :newType="this.actualType"
      :sensors="tempSensors"
    />
    <DeviceSearch
      v-if="showSensorEditing && !this.selectedSensor"
      @add-sensor="insertNewSensor"
      @close="showSensorEditing = false"
      :initalType="this.actualType"
      :singleSelect="true"
      :bridgeSpan="selectedPosition.bridgeSpan"
      :bridgeSide="selectedPosition.bridgeSide"
    />
    <transition name="settings">
      <Groups v-if="showFDDGroups" :sensorType="actualType" :structure="this.getSelectedStructure" @close-groups="showFDDGroups = false" />
    </transition>
    <SensorToggle id="sensortoggle" :sensors="tempSensors" :showAll="true" @type-selected="this.typeSelected" />
    <div class="extra-control">
      <button @click="showFDDGroups = true" :disabled="(this.actualType !== 'deck' && this.actualType !== 'accelerometer') || !(this.validSensors.length > 0)">FDD</button>
    </div>
    <!-- Elemento Bridge -->
    <div class="bridge-child">
      <div id="bridge-image" @click="closeModals($event)"></div>
      <!-- Sensor Overlay -->
      <div class="sensors-container">
        <!-- Bottone del sensore -->
        <div class="tooltip" v-for="(item, index) in matrix" :key="index" width="64px" height="64px" :style="{ marginTop: computeMarginTop(item) + 'px', marginLeft: computeMarginLeft(item) + 'px' }">
          <button
            v-if="!item.empty"
            class="button"
            @click="
              editSensor(item);
              closeModals($event);
            "
          >
            {{ item.eui.substr(item.eui.length - 5) }}
          </button>
          <button
            v-else
            class="button free-slot"
            @click="
              insertSensor(item);
              closeModals($event);
            "
          >
            +
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeviceSearch from "../../components/ui/DeviceSearch.vue";
import SensorToggle from "../../components/ui/SensorToggle.vue";
import SensorInfo from "../admin/modal/SensorInfo.vue";
import Groups from "../admin/modal/GroupsV2.vue";
import { mapGetters, mapActions } from "vuex";
import api from "../../services/api";
import $ from "jquery";

/*
BridgeDraw ha il compito di plottare l'immagine del ponte, con i relativi sensori sopra.
Emette un evento 'sensor-selected' quando viene selezionato un sensore.
*/
export default {
  name: "bridge-min",
  components: {
    DeviceSearch,
    SensorToggle,
    Groups,
    SensorInfo,
  },
  data() {
    return {
      tempSensors: [],
      selectedSensor: null,
      selectedPosition: {},
      temperature: 18,
      isInfoVisible: false,
      isTempInfoVisible: false,
      actualType: null,
      showSensorEditing: false,
      showFDDGroups: false,
    };
  },
  props: {
    spans: Number,
    sensors: Array,
  },
  methods: {
    ...mapActions({
      deleteSensorFromStructure: "admin/deleteSensor",
      insertNewSensorToStructure: "admin/addSensor",
      updateSensorToCustomer: "admin/updateSensor",
    }),
    insertSensor(slot) {
      this.selectSensor(null); // Setto a null per indicare la creazione di un nuovo sensore
      this.$set(this.selectedPosition, "bridgeSpan", slot.bridgeSpan);
      this.$set(this.selectedPosition, "bridgeSide", slot.bridgeSide);
      this.showSensorEditing = true;
    },
    editSensor(sensor) {
      this.$set(this.selectedPosition, "bridgeSpan", null);
      this.$set(this.selectedPosition, "bridgeSide", null);
      this.selectSensor(sensor);
      this.showSensorEditing = true;
    },
    async deleteSensor(sensorEUI) {
      await this.deleteSensorFromStructure(sensorEUI);
    },
    async resetSensorStats(sensorEUI) {
      await api.resetSensorStats(this.getSelectedStructure._id, this.getSelectedUser._id, sensorEUI);
      this.$set(this.selectedSensor, "stats", {});
    },
    async insertNewSensor(sensor) {
      await this.insertNewSensorToStructure(sensor);
    },
    async updateSensor(sensorEUI, sensorInfo) {
      let sensorObject = {};
      sensorObject.eui = sensorEUI;
      sensorObject.sensorInfo = sensorInfo;
      await this.updateSensorToCustomer(sensorObject);
    },
    computeMarginTop(sensor) {
      let marginTop;
      marginTop = sensor.bridgeSide * 155 - 255;
      return marginTop;
    },
    computeMarginLeft(sensor) {
      return (sensor.bridgeSpan / 10) * 343 - 290;
    },
    closeModals(e) {
      if (!e.target.className || e.target.className != "info-svg") {
        this.isInfoVisible = false;
        this.isTempInfoVisible = false;
      }
    },
    selectSensor(sensor) {
      this.selectedSensor = sensor;
    },
    appendBridgeSection(nSpanes) {
      //img.width = "1280";
      let bridgeWrapper = document.getElementById("bridge-image");
      for (let i = 0; i < nSpanes; i++) {
        var img = document.createElement("img");
        img.src = require("../../../public/assets/BridgeChop.png");
        bridgeWrapper.prepend(img);
      }
    },
    typeSelected(type) {
      // Se viene selezionato un tipo ed è diverso da quello del sensore attualmente selezionato, seleziono un nuovo sensore
      this.actualType = type;
      //if(type != this.selectedFromParent.type)
      this.selectSensor(this.validSensors[0]);
    },
  },
  computed: {
    ...mapGetters({
      getSelectedStructure: "admin/getSelectedStructure",
      getSelectedUser: "admin/getSelectedUser",
    }),
    validSensors: function() {
      let type = this.actualType;
      return this.tempSensors.filter(function(el) {
        return el.type === type;
      });
    },
    matrix() {
      let columns = this.spans * 2;
      let rows = 2;
      let itemsArray = [];
      for (let i = 0; i < columns; i++) {
        for (let j = 0; j < rows; j++) {
          let foundSensor = this.validSensors.filter((s) => s.bridgeSide === j && s.bridgeSpan / 5 === i);
          if (foundSensor[0]) {
            itemsArray.push(foundSensor[0]);
          } else {
            itemsArray.push({
              empty: true,
              bridgeSide: j,
              bridgeSpan: i * 5,
            });
          }
        }
      }
      return itemsArray;
    },
  },
  watch: {
    sensors: {
      // Quando cambiano i sensori della struttura Seleziono un nuovo sensore e disegno il ponte nuovo
      handler: async function(newValue, oldValue) {
        this.tempSensors = await JSON.parse(JSON.stringify(this.sensors));
        if (newValue[0] && oldValue[0] && newValue[0].eui != oldValue[0].eui) {
          this.selectSensor(newValue[0]);
          let bridgeWrapper = document.getElementById("bridge-image");
          bridgeWrapper.textContent = "";
          this.appendBridgeSection(this.spans);
        }
      },
      deep: true,
    },
    spans: {
      handler: async function(newValue) {
        this.appendBridgeSection(newValue);
      },
    },
  },
  async mounted() {
    this.tempSensors = await JSON.parse(JSON.stringify(this.sensors));
    this.tempSensors = this.tempSensors.map((sn, index) => {
      return {
        ...sn,
        bridgeSide: sn.bridgeSide != null && sn.bridgeSide != undefined ? sn.bridgeSide : index + 1 > this.spans ? 1 : 0,
        bridgeSpan: sn.bridgeSpan != null && sn.bridgeSpan != undefined ? sn.bridgeSpan : ((index + 1) % this.spans) * 5,
      };
    });
    this.appendBridgeSection(this.spans),
      // Quando clicco sull'immagine aggiungo classe "active"
      $(".bridge-child").mousedown(function() {
        var hovered = $(".tooltip").find(".button:hover").length;

        if (hovered < 1) {
          $(".bridge-child").addClass("active");
        }
      });
    // Quando smetto di cliccare la rimuovo
    $(".bridge-child").mouseup(function() {
      $(".bridge-child").removeClass("active");
    });
    // Quando sposto il mouse la rimuovo
    $(".bridge-child").mouseleave(function() {
      $(".bridge-child").removeClass("active");
    });
    // Quando effettuo un win resize riporto il ponte in posizione iniziale
    $(window).resize(function() {
      $(".bridge-child").css("left", "0px");
    });
    // Con doppio click riporto il ponte in posizione iniziale
    $(".bridge-child").dblclick(function() {
      $(".bridge-child").css("left", "0px");
    });
    // Drag handler
    $(".bridge-child").draggable({
      cursor: "move",
      containment: "bridge-parent",
      axis: "x",
      stop: function() {
        let left = $(".bridge-child").position().left;
        let width = $("#bridge-image").width();
        if (left > width - 500 || left < -(width - 500)) {
          $(".bridge-child").css("left", "0px");
        }
      },
    });
  },
};
</script>
<style scoped>
.modal-info {
  border-radius: 10px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(91, 96, 118);
  -webkit-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 9px 37px -20px rgba(0, 0, 0, 0.75);
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

#info-deck {
  position: absolute;
  top: -120px;
  left: 460px;
  max-width: 500px;
}

#info-temp {
  position: absolute;
  top: 60px;
  width: 500px;
  left: 80px;
}
.bridge-parent {
  max-width: 100vw;
  background-color: rgb(45, 48, 65);
  display: grid;
  grid-template-rows: max-content;
  grid-template-columns: max-content;
  align-items: baseline;
}
.bridge-child {
  display: grid;
  justify-content: center;
  transition: transform 0.3s;
  transform: scale(1);
  margin-top: 100px;
  grid-column: 1/3;
}
.bridge-img {
  top: 0;
  left: 0;
  cursor: pointer;
  filter: drop-shadow(rgba(0, 0, 0, 0.5) 1px 7px 4px);
}

#bridge-image {
  display: flex;
}
.bridge-child.active > .bridge-img {
  cursor: grabbing;
  cursor: -webkit-grabbing;
}

.bridge-child.active {
  transform: scale(1.02);
}

#help-tip {
  position: absolute;
  width: 430px;
  top: 180px;
  left: 120px;
  text-align: left;
  display: grid;
}

#temperature {
  position: absolute;
  display: grid;
  width: 205px;
  top: 220px;
  left: 60%;
  text-align: left;
}

@media only screen and (min-width: 1300px) {
  #temperature {
    position: absolute;
    width: 205px;
    top: 220px;
    left: 6 0vw;
    text-align: left;
  }
}

@media only screen and (max-width: 1000px) {
  #temperature {
    position: absolute;
    width: 205px;
    top: 300px;
    left: 120px;
    text-align: left;
  }
}

#sensortoggle {
  /*   position: absolute;
  top: 267px;
  left: 117px; */
  /* width: 600px; */
  width: fit-content;
  height: 40px;
  margin-top: 15px;
  margin-left: 35px;
  grid-row: 1;
  grid-column: 1;
}
.extra-control {
  display: flex;
  grid-row: 1;
  grid-column: 2;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 10%;
  align-items: center;
  height: 60px;
}
.extra-control button {
  cursor: pointer;
  color: white;
  padding: 2px 20px;
  outline: none;
  background-color: rgb(80, 84, 105);
  border: solid 2px white;
  font-weight: 800;
  font-size: 1.2em;
  border-radius: 10px;
}
button:disabled {
  background-color: grey;
  cursor: default;
}
@media all and (min-width: 300px) {
  #help-tip {
    left: 30px;
  }
}

.over-info > h2 {
  grid-row: 1;
  grid-column: 1;
  font-size: 1.2em;
  color: white;
  margin: 0;
}
.over-info > p {
  grid-column: 1;
  grid-row: 2;
  margin: 0;
}
.over-info > img {
  justify-self: end;
  grid-column: 1;
  grid-row: 1;
}

.sensors-container {
  width: 640px;
  top: 0;
  left: 0;
  position: relative;
}

.tooltip {
  font-size: smaller;
  position: absolute;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s linear, opacity 0.2s linear;
  transition-delay: 0s;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 1px;
  padding: 1px 12px;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 10%;
  margin-left: -7vw;
  font-family: monospace;
}

.tooltip:hover > .tooltiptext {
  visibility: visible;
  opacity: 0.88;
  transition-delay: 1s;
}

.button {
  width: 110px;
  padding: 6px;
  display: inline-block;
  padding: 3px 18px;
  font-size: 1.2em;
  font-weight: 700;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  color: #fff;
  background-color: rgb(80, 84, 105);
  border: solid 5px white;
  border-radius: 20px;
  transition: transform 0.1s linear;
}

.free-slot {
  border: hidden;
  border-radius: 0px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.4);
  margin-top: -10px;
  color: rgb(45, 48, 65);
}

.selected-button {
  background-color: rgb(35, 145, 254);
}

.button:hover {
  /*   background-color: rgb(47, 112, 177); */
  transition: transform 0.1s linear;
  -ms-transform: scale(1.126) translate3d(0, 0, 0);
  -webkit-transform: scale(1.126) translate3d(0, 0, 0);
  transform: scale(1.126) translate3d(0, 0, 0);
}

.button:active {
  background-color: rgb(47, 112, 177);
  transform: translateY(1px);
}

/* Modal Device Search */
#device-search {
  right: 30%;
  top: 83px;
  max-width: 770px;
  background-color: rgb(45, 48, 65);
  z-index: 12;
}
</style>
