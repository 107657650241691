<template>
  <div class="outer-wrap">
    <Loading :soft="true" v-if="this.loading" />
    <div class="admin" id="page-wrap">
      <NavBar v-show="true" id="navbar" :userList="this.getUsers" :selectedUser="this.getSelectedUser" @logout="this.logout" @add-customer="openNewUserModal" @edit-customer="editUser" @groups-toggle="showGroupsModal = !showGroupsModal" @select-customer="this.selectUser" />
      <StructureList v-if="this.getSelectedUser && this.getSelectedUser.username != 'admin'" />
      <GatewayList @putgateways="putGWS" @deletegateway="deleteGW" :lockedRegion="lockedGatewayRegion" v-if="getGateways" id="gateways" :gateways="getGateways" :selectedStructure="getSelectedStructure" />
      <BridgeDraw v-if="this.getSelectedStructure && this.getSelectedStructure.type != 'generic' && this.getSelectedUser && this.getSelectedUser.username != 'admin'" :spans="parseInt(this.getSelectedStructure.spans)" :sensors="this.getSelectedStructure.sensors" />
      <Generic
        id="generic"
        v-if="this.getSelectedStructure && this.getSelectedStructure.type === 'generic' && this.getSelectedUser && this.getSelectedUser.username != 'admin'"
        :selectedStructure="this.getSelectedStructure"
        :adminMode="true"
        @update-sensor-position="updateSensorPosition"
        @update-sensor="updateSensor"
        @add-sensor="addSensor"
        @delete-sensor="deleteSensor"
        @delete-image="deleteImage"
        @add-image="addImage"
      />

      <transition name="settings">
        <UserInfo @close-userinfo="showUserEditModal = false" @delete-user="deleteCustomer" @update-customer="updateCustomer" :user="this.getSelectedUser" v-if="showUserEditModal && this.getSelectedUser" />
        <UserInfo @close-userinfo="showNewUserModal = false" @add-customer="newUser" v-if="showNewUserModal" />
      </transition>
    </div>
  </div>
</template>
<script>
import NavBar from "../../components/admin/NavigationBar.vue";
import StructureList from "../../components/admin/StructureList.vue";
import UserInfo from "../../components/admin/modal/UserInfo.vue";
import Loading from "../../components/ui/LoadingLogo.vue";
import GatewayList from "../../components/admin/ui/GatewayList.vue";
import BridgeDraw from "../../components/admin/BridgeDrawMin.vue";
import Generic from "../../components/structures/Generic.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "admin",
  components: {
    NavBar,
    StructureList,
    UserInfo,
    Loading,
    GatewayList,
    BridgeDraw,
    Generic,
  },
  data() {
    return {
      showSidebar: false,
      showUserEditModal: false, // Modale Modifica Cliente
      showNewUserModal: false, // Modale Aggiungi Cliente
      showGroupsModal: false, // Modale gruppi sensori
    };
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
      fetchUsers: "admin/fetchUsers",
      selectUser: "admin/selectUser",
      fetchStructures: "admin/fetchStructures",
      fetchGateways: "admin/fetchGateways",
      putGateways: "admin/putGateways",
      deleteGateway: "admin/deleteGateway",
      selectStructure: "admin/selectStructure",
      deleteCustomer: "admin/deleteCustomer",
      addCustomer: "admin/addCustomer",
      setLoading: "admin/setLoadingStatus",
      updateCustomerDetails: "admin/editCustomer",
      editSensorPosition: "admin/editSensorPosition",
      insertNewSensorToStructure: "admin/addSensor",
      deleteSensorFromStructure: "admin/deleteSensor",
      deleteStructureImage: "admin/deleteStructureImage",
      addStructureImage: "admin/addStructureImage",
      updateSensorToCustomer: "admin/updateSensor",
      fetchNsUsage: "admin/fetchNsUsage",
    }),
    async putGWS(gws) {
      this.setLoading(true);
      await this.putGateways(gws);
      this.setLoading(false);
    },
    async deleteGW(gwID) {
      this.setLoading(true);
      await this.deleteGateway(gwID);
      this.setLoading(false);
    },
    async deleteImage(imageID) {
      this.setLoading(true);
      await this.deleteStructureImage(imageID);
      this.setLoading(false);
    },
    async addImage(image) {
      this.setLoading(true);
      await this.addStructureImage(image);
      this.setLoading(false);
    },
    async addSensor(sensor) {
      this.setLoading(true);
      await this.insertNewSensorToStructure(sensor);
      this.setLoading(false);
    },
    async deleteSensor(sensor) {
      this.setLoading(true);
      await this.deleteSensorFromStructure(sensor);
      this.setLoading(false);
    },
    async newUser(user) {
      this.setLoading(true);
      await this.addCustomer(user);
      this.setLoading(false);
    },
    async updateCustomer(customer) {
      this.setLoading(true);
      await this.updateCustomerDetails({
        customerID: customer.customerID,
        customerInfo: customer.updateInfo,
      });
      this.setLoading(false);
    },
    openNewUserModal() {
      this.showNewUserModal = true;
    },
    editUser() {
      this.showUserEditModal = true;
    },
    async deleteUser(customerID) {
      this.setLoading(true);
      await this.deleteCustomer(customerID);
      this.setLoading(false);
    },
    async updateSensorPosition(newSensorsPosition) {
      this.setLoading(true);
      await this.editSensorPosition(newSensorsPosition);
      this.setLoading(false);
    },
    async updateSensor(sensorObj) {
      let sensorObject = {};
      sensorObject.eui = sensorObj.eui;
      sensorObject.sensorInfo = sensorObj.sensorInfo;
      this.setLoading(true);
      await this.updateSensorToCustomer(sensorObj);
      this.setLoading(false);
    },
  },
  computed: {
    ...mapGetters({
      getUsers: "admin/getUsers",
      getSelectedUser: "admin/getSelectedUser",
      getStructures: "admin/getStructures",
      getGateways: "admin/getGateways",
      getSelectedStructure: "admin/getSelectedStructure",
      loading: "admin/getLoadingStatus",
    }),
    // Regione da bloccare sulla ricerca dei Gateway
    lockedGatewayRegion() {
      let region = null;
      if (!this.getSelectedStructure || !this.getSelectedStructure.loraRegion) return region;
      const fromStruct = this.getSelectedStructure && this.getSelectedStructure.loraRegion.substring(0, 2);
      if (fromStruct === "EU" || fromStruct === "AU" || fromStruct === "US") {
        region = fromStruct;
      }
      return region;
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.fetchUsers();
    await this.fetchNsUsage()
    if (this.getUsers.filter((us) => us.type === "standard").length > 0) {
      await this.selectUser(this.getUsers.filter((us) => us.type === "standard")[0]._id);
      await this.fetchStructures(this.getSelectedUser._id);
      await this.fetchGateways();
      console.log("Mounted Admin");
    }
    this.setLoading(false);
  },
  watch: {
    getSelectedUser: {
      handler: async function(newValue) {
        if (newValue) {
          this.setLoading(true);
          await this.fetchStructures(newValue._id);
          if (this.getStructures.length > 0) {
            await this.selectStructure(this.getStructures[0]._id);
          } else {
            await this.selectStructure(null);
          }
          this.setLoading(false);
          console.log("U: ", newValue._id);
        }
      },
    },
    getSelectedStructure: {
      handler: async function(newValue, oldValue) {
        console.log("S ", newValue, oldValue);
        this.setLoading(true);
        await this.fetchGateways();
        this.setLoading(false);
      },
    },
  },
};
</script>
<style scoped>
#navbar {
  transition: transform 0.3s;
  position: fixed;
  width: 100%;
  min-width: 840px;
  margin-bottom: 50px;
}
#generic,
#gateways {
  margin-top: 20px;
}
.admin {
  width: 100%;
  height: fit-content;
  color: white;
  position: absolute;
  display: grid;
  grid-template-rows: min-content;
  background-color: rgb(32, 34, 47);
  font-size: 1em;
  padding-top: 50px;
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-family: "Poppins", sans-serif;
  -webkit-transition: transform 0.6s ease-in-out;
  -moz-transition: transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
}
</style>
